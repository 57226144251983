<template>
  <loading v-if="pageLoading" :loading="pageLoading" />
  <container v-else>
    <page-header>
      <grid>
        <grid-column eight>
          <h1>
            <editable-text
              :value="relationship.name"
              :loading="patchingField === 'name'"
              placeholder="Add Name"
              @update="(value) => patchRelationship('name', value)"
            />
          </h1>
        </grid-column>
        <grid-column eight right-aligned middle-aligned>
          <btn
            icon="trash icon"
            class="btn"
            tooltip="Delete Relationship"
            variant="secondary"
            @click.native="$refs.confirm.show()"
          />
        </grid-column>
      </grid>
    </page-header>
    <grid class="add-relationship-container">
      <grid-column sixteen>
        <h5>Add New {{ relationship.name }}</h5>
        <flex align="flex-end">
          <div class="role-dropdown">
            <h3>
              <editable-text
                :value="relationship.superiorTitle"
                :loading="patchingField === 'superiorTitle'"
                placeholder="Add Role Name"
                @update="(value) => patchRelationship('superiorTitle', value)"
              />
            </h3>
            <dropdown
              ref="roleOne"
              name="Role One"
              :default-text="selectSuperiorPlaceholderText"
              fluid
              selection
              search
              clearable
              @update="(value) => (firstUserId = value)"
            >
              <dropdown-item
                v-for="user in users"
                :key="user.id"
                :value="user.id"
              >
                <g-image
                  avatar
                  :src="user.avatar || defaultUserAvatar"
                  alt="Role one avatar for relationship"
                />
                {{ user.fullName }}
              </dropdown-item>
            </dropdown>
          </div>
          <i class="fas fa-long-arrow-alt-right grey connection-icon" />
          <div class="role-dropdown">
            <h3>
              <editable-text
                :value="relationship.subordinateTitle"
                :loading="patchingField === 'subordinateTitle'"
                placeholder="Add Role Name"
                @update="
                  (value) => patchRelationship('subordinateTitle', value)
                "
              />
            </h3>
            <dropdown
              ref="roleTwo"
              name="Role Two"
              :default-text="selectSubordinatePlaceholderText"
              selection
              search
              clearable
              fluid
              @update="(value) => (secondUserId = value)"
            >
              <dropdown-item
                v-for="user in users"
                :key="user.id"
                :value="user.id"
              >
                <g-image
                  avatar
                  :src="user.avatar || defaultUserAvatar"
                  alt="Role two avatar for relationship"
                />
                {{ user.fullName }}
              </dropdown-item>
            </dropdown>
          </div>
          <btn
            class="btn"
            :loading="addingUsersRelationship"
            :disabled="!firstUserId || !secondUserId"
            @click.native="createUsersRelationship"
          >
            Add
          </btn>
        </flex>
      </grid-column>
    </grid>

    <loading v-if="errorLoading" :loading="errorLoading" />
    <template v-else>
      <list middle-aligned divided relaxed>
        <list-item
          v-for="usersRelationship in usersRelationships"
          :key="usersRelationship.id"
        >
          <template v-if="editingUsersRelationship === usersRelationship.id">
            <dropdown
              ref="editRoleOne"
              name="Edit Role One"
              :default-text="selectSuperiorPlaceholderText"
              scrolling
              floating
              :initial-value="usersRelationship.firstUserId"
              :loading="patchingUserRelationshipId === usersRelationship.id"
              @update="
                (value) =>
                  patchUserRelationship(
                    usersRelationship.id,
                    'firstUserId',
                    value
                  )
              "
            >
              <dropdown-search />
              <dropdown-item
                v-for="user in users"
                :key="user.id"
                :value="user.id"
              >
                <g-image
                  avatar
                  :src="user.avatar || defaultUserAvatar"
                  alt="Role one avatar for relationship"
                />
                {{ user.fullName }}
              </dropdown-item>
            </dropdown>
            <i class="fas fa-long-arrow-alt-right grey connection-icon" />
            <dropdown
              ref="editRoleTwo"
              name="Edit Role Two"
              :default-text="selectSubordinatePlaceholderText"
              scrolling
              floating
              :initial-value="usersRelationship.secondUserId"
              :loading="patchingUserRelationshipId === usersRelationship.id"
              @update="
                (value) =>
                  patchUserRelationship(
                    usersRelationship.id,
                    'secondUserId',
                    value
                  )
              "
            >
              <dropdown-search />
              <dropdown-item
                v-for="user in users"
                :key="user.id"
                :value="user.id"
              >
                <g-image
                  avatar
                  :src="user.avatar || defaultUserAvatar"
                  alt="Role two avatar for relationship"
                />
                {{ user.fullName }}
              </dropdown-item>
            </dropdown>
            <list-content float-right>
              <btn
                icon="fas fa-save"
                class="btn"
                tooltip="Save"
                variant="secondary"
                compact
                :loading="usersRelationshipsLoading.get(usersRelationship.id)"
                @click.native.prevent="() => (editingUsersRelationship = null)"
              />
              <btn
                class="btn"
                dismiss
                compact
                tooltip="Remove"
                :loading="usersRelationshipsLoading.get(usersRelationship.id)"
                @click.native.prevent="
                  deleteUsersRelationship(usersRelationship.id)
                "
              />
            </list-content>
          </template>
          <template v-else>
            <list-content>
              <flex justify="space-between" align="center">
                <flex align="center" gap="0.5rem">
                  <g-image
                    avatar
                    :src="
                      usersRelationship.firstUser.avatar || defaultUserAvatar
                    "
                    alt="Role one avatar for relationship"
                  />
                  {{ usersRelationship.firstUser.fullName }}
                  <i
                    class="
                      fas
                      fa-long-arrow-alt-right
                      grey
                      connection-icon
                      viewer
                    "
                  />
                  <g-image
                    avatar
                    :src="
                      usersRelationship.secondUser.avatar || defaultUserAvatar
                    "
                    alt="Role one avatar for relationship"
                  />
                  {{ usersRelationship.secondUser.fullName }}
                </flex>
                <flex align="center">
                  <btn
                    icon="fas fa-edit"
                    class="btn"
                    tooltip="Edit"
                    variant="secondary"
                    compact
                    :loading="
                      usersRelationshipsLoading.get(usersRelationship.id)
                    "
                    @click.native.prevent="
                      () => (editingUsersRelationship = usersRelationship.id)
                    "
                  />
                  <btn
                    class="btn"
                    dismiss
                    compact
                    tooltip="Remove"
                    :loading="
                      usersRelationshipsLoading.get(usersRelationship.id)
                    "
                    @click.native.prevent="
                      deleteUsersRelationship(usersRelationship.id)
                    "
                  />
                </flex>
              </flex>
            </list-content>
          </template>
        </list-item>

        <placeholder
          v-if="usersRelationships.length === 0"
          centered
          vertically-aligned
          light
        >
          {{ placeholderText }}
        </placeholder>
      </list>

      <pagination
        v-if="
          usersRelationshipsPagination &&
          usersRelationshipsPagination.lastPage > 1
        "
        :num-of-pages="usersRelationshipsPagination.lastPage"
      />
    </template>
    <confirm-modal
      ref="confirm"
      negative
      approve-text="Delete Relationship"
      deny-text="Cancel"
      @approve="deleteRelationship"
    >
      Are you sure you want to delete the relationship {{ relationship.name }}?
    </confirm-modal>
  </container>
</template>

<script>
import { api } from '@/api'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import { toast } from '@/toasts'
import loading from '@/components/v2/loading.vue'
import container from '@/components/v2/container.vue'
import pagination from '@/components/v2/pagination/pagination.vue'
import btn from '@/components/v2/btn.vue'
import editableText from '@/components/v2/editable_text.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import placeholder from '@/components/v2/placeholder.vue'
import pageHeader from '@/components/v2/page_header.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownSearch from '@/components/v2/dropdown/search.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import confirmModal from '@/components/v2/confirm_modal.vue'
import listContent from '@/components/v2/list/content.vue'
import gImage from '@/components/v2/image.vue'
import flex from '@/components/v2/flex.vue'

import defaultUserAvatar from '@/assets/img/profile_avatar_small.png'

export default {
  components: {
    list,
    listItem,
    loading,
    container,
    pagination,
    btn,
    grid,
    gridColumn,
    placeholder,
    pageHeader,
    editableText,
    dropdown,
    dropdownSearch,
    dropdownItem,
    confirmModal,
    listContent,
    gImage,
    flex,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      defaultUserAvatar,
      relationship: {},
      usersRelationships: [],
      usersRelationshipsPagination: null,
      pageLoading: false,
      errorLoading: false,
      patchingField: null,
      users: [],
      firstUserId: null,
      secondUserId: null,
      patchingUserRelationshipId: null,
      addingUsersRelationship: false,
      usersRelationshipsLoading: new Map(),
      usersRelationshipsPatching: new Map(),
      editingUsersRelationship: null,
    }
  },
  computed: {
    placeholderText() {
      return 'No relationships yet, you can add them using the dropdowns'
    },
    selectSuperiorPlaceholderText() {
      return `Select ${this.relationship.superiorTitle || 'Person'}`
    },
    selectSubordinatePlaceholderText() {
      return `Select ${this.relationship.subordinateTitle || 'Person'}`
    },
  },
  watch: {
    $route: {
      handler() {
        this.getPage()
      },
    },
  },
  mounted() {
    this.getPage()
  },
  methods: {
    async getPage() {
      try {
        this.pageLoading = true
        await this.getRelationship()
        await this.getUsersRelationships()
        await this.getUsers()
      } catch (error) {
        toast.error(error)
      } finally {
        this.pageLoading = false
      }
    },
    async getRelationship() {
      try {
        this.relationship = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/relationships/${this.id}`
          )
        ).data
      } catch (error) {
        toast.error(error)
      }
    },
    async getUsersRelationships() {
      try {
        const { data, cursor } = await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/users-relationships`,
          {
            page: parseInt(this.$route.query.page || 1, 10),
            relationshipId: parseInt(this.id, 10),
            userStatus: ['active', 'onboarding'],
          }
        )
        this.usersRelationships = data
        this.usersRelationshipsPagination = cursor
        this.usersRelationshipsLoading = new Map(
          this.usersRelationships.map((userRelationship) => [
            userRelationship.id,
            false,
          ])
        )
      } catch (error) {
        toast.error(error)
      }
    },
    async getUsers(search) {
      let response
      while (!response || response.cursor.nextPage !== null) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/users`,
            {
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              ...(search ? { search } : {}),
              status: ['active', 'onboarding'],
              sort: 'preferredFirstName,lastName,id',
            }
          )
          this.users = this.users.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
    },
    async patchRelationship(field, value) {
      try {
        this.patchingField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/relationships/${this.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingField = null
      }
      await this.getRelationship()
    },
    async deleteRelationship() {
      try {
        this.pageLoading = true
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/relationships/${this.id}`
        )
        this.$router.push({ name: 'relationships' })
      } catch (error) {
        toast.error(error)
      } finally {
        this.pageLoading = false
      }
    },
    async createUsersRelationship() {
      try {
        this.addingUsersRelationship = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/users-relationships`,
          {
            firstUserId: parseInt(this.firstUserId, 10),
            secondUserId: parseInt(this.secondUserId, 10),
            relationshipId: parseInt(this.id, 10),
          }
        )
        this.clearDropdowns()
      } catch (error) {
        toast.error(error)
      } finally {
        this.addingUsersRelationship = false
      }

      await this.getUsersRelationships()
    },
    async patchUserRelationship(usersRelationshipId, field, value) {
      try {
        this.patchingUserRelationshipId = parseInt(usersRelationshipId, 10)
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/users-relationships/${usersRelationshipId}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
        // force the list to rerender to correct the error in the UI
        this.errorLoading = true
      } finally {
        this.patchingUserRelationshipId = null
      }

      await this.getUsersRelationships()
      this.errorLoading = false
    },
    async deleteUsersRelationship(usersRelationshipId) {
      try {
        this.usersRelationshipsLoading = new Map(
          this.usersRelationshipsLoading.set(usersRelationshipId, true)
        )
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/users-relationships/${usersRelationshipId}`
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.usersRelationshipsLoading = new Map(
          this.usersRelationshipsLoading.set(usersRelationshipId, false)
        )
      }

      await this.getUsersRelationships()
    },
    clearDropdowns() {
      this.firstUserId = null
      this.secondUserId = null
      this.$refs.roleOne.clear()
      this.$refs.roleTwo.clear()
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/text.less';
@import '~@/assets/less/borders.less';

.connection-icon {
  display: inline-block;
  padding: 0 1em;
  font-size: @large-icon-font-size;
  color: @grey;
  transform: translateY(0.25rem);

  &.viewer {
    transform: translateY(0);
  }
}

.btn {
  position: relative;
  margin-bottom: 0.05em;
  margin-left: 1em;
}

.add-relationship-container {
  padding: 0.5em;
  margin: 2em 0;
  border: @light-border;
  border-radius: @standard-border-radius;

  .connection-icon {
    transform: translateY(-0.5em);
  }
}

.role-dropdown {
  flex-grow: 2;
}
</style>
