<template>
  <modal ref="modal" :size="size" closable v-on="$listeners">
    <modal-content centered>
      <slot />
    </modal-content>
    <modal-actions centered space-between>
      <btn class="deny" variant="default">
        {{ denyText }}
      </btn>
      <btn class="approve" :variant="negative ? 'negative' : 'primary'">
        {{ approveText }}
      </btn>
    </modal-actions>
  </modal>
</template>

<script>
import modal from '@/components/v2/modal/modal.vue'
import modalContent from '@/components/v2/modal/content.vue'
import modalActions from '@/components/v2/modal/actions.vue'
import btn from '@/components/v2/btn.vue'

export default {
  components: {
    modal,
    modalContent,
    modalActions,
    btn,
  },
  props: {
    negative: {
      type: Boolean,
      default: false,
    },
    approveText: {
      type: String,
      default: 'Yes',
    },
    denyText: {
      type: String,
      default: 'No',
    },
    size: {
      type: String,
      default: 'tiny',
      validator: (value) =>
        [
          null,
          'mini',
          'tiny',
          'small',
          'large',
          'big',
          'huge',
          'massive',
        ].includes(value),
    },
  },
  methods: {
    show() {
      this.$refs.modal.show()
    },
  },
}
</script>

<style lang="less" scoped>
.ui.modal {
  .content {
    margin-top: 1em;
    margin-bottom: -1em;
  }
}
</style>
